.centered-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto; /* Prend toute la hauteur de la vue */
    min-height: 60vh;
    
}

.test2-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin-left: 0px;
}

.input-field {
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.btn {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    background-color: #2A4C21;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
}
.btn2 {
    width: 97%;
    padding: 10px;
    margin: 10px 0;
    background-color: #2A4C21;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
}
.btn3 {
    padding: 10px;
    margin: 10px 0;
    background-color: #2A4C21;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.btn:hover {
    background-color: #1f3b16;
}

.error {
    color: red;
    margin-top: 10px;
}

.password-reset-container {
    margin-top: 20px;
}

.password-reset-container h2 {
    margin-bottom: 10px;
}

.password-reset-container form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.password-reset-container input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.password-reset-container button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.password-reset-container button:hover {
    background-color: #0056b3;
}

/* Bouton de vérification de l'e-mail (rouge) */
.btn-warning {
    background-color: #ffcc00; /* Couleur d'arrière-plan jaune */
    color: white; /* Couleur du texte */
}

/* Changement de couleur au survol pour le bouton d'avertissement */
.btn-warning:hover {
    background-color: #e6b800; /* Couleur d'arrière-plan au survol */
}

/* Bouton d'information (vert) */
.btn-info {
    background-color: #28a745; /* Couleur d'arrière-plan vert */
    color: white; /* Couleur du texte */
}

/* Changement de couleur au survol pour le bouton d'information */
.btn-info:hover {
    background-color: #218838; /* Couleur d'arrière-plan au survol */
}

/* Bouton principal (bleu) */
.btn-primary {
    background-color: #007bff; /* Couleur d'arrière-plan bleu */
    color: white; /* Couleur du texte */
}

/* Changement de couleur au survol pour le bouton principal */
.btn-primary:hover {
    background-color: #0056b3; /* Couleur d'arrière-plan au survol */
}

/* Styles pour désactiver le bouton */
.btn:disabled {
    background-color: #cccccc; /* Couleur de fond grise pour un bouton désactivé */
    color: #666666; /* Couleur du texte gris */
    cursor: not-allowed; /* Curseur non autorisé */
}

/* Style pour le conteneur */
.phone-input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}


/* Style pour l'icône d'information */
.info-icon {
    margin-left: 8px;
    cursor: pointer;
    color: #888;
    font-size: 18px;
}

/* Style pour le tooltip */
.tooltip-text {
    visibility: hidden;
    width: 220px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -110px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 14px;
}

/* Affiche le tooltip au survol */
.phone-input-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

@media all and (max-width: 500px) {
    .centered-container {
        max-width: 300px;
    }
    
    .test2-form {
        max-width: 300px;
    }
}