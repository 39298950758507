h1{
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: justify;
    color: #2A4C21;
}
h2{
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: justify;
    color: #2A4C21;
}

.Photo{
    width: 200px; /* Largeur de l'image */
    height: auto; /* Hauteur automatique pour conserver les proportions */
    max-width: 100%; /* Assurez-vous que l'image ne dépasse pas le conteneur */
}

.content {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: justify;
    display: flex;
    margin-bottom: 20px;
}

.content-2 {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: justify;
    display: flex;
    margin-bottom: 20px;
}

.text-container {
    flex: 1; /* Take up remaining space on the left */
    margin-right: 30px; /* Space between text and image */
    text-align: left; /* Align text to the left */
    margin-left: -20px;
}

.texte-environnement{
    flex: 1; /* Take up remaining space on the left */
    text-align: left; /* Align text to the left */
    margin-left: -20px;
}

.help-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow-y: auto;
}

/* Style pour le contenu de l'aide */
.help-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 80%;
    max-height: 80vh; /* Limite la hauteur à 80% de la hauteur de la vue */
    overflow-y: auto; /* Active le défilement vertical si le contenu dépasse */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.help-content h2 {
    margin-top: 0;
    color: #2A4C21;
}

.edit-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #2A4C21;
    color: white;
    cursor: pointer;
    margin: 10px 0;
    margin-left: 20px; /* Ajustez cette valeur pour aligner le bouton avec le texte */
    font-size: 16px;
    transition: background-color 0.3s ease;
    display: block; /* Assure que le bouton se trouve sur une nouvelle ligne */
}

.edit-button:hover {
    background-color: #1a3a16;
}

.content .image-container .Photo{
    border: 4px solid #2A4C21;
    border-radius: 
        50% 10% 50% 10% /
        10% 50% 10% 50%;
    width: 227px;
    height: 278px;
}

@media all and (max-height: 722px) {
    .footer{
        position: relative !important;
    }
}

@media all and (max-width: 768px) {
    .content{
        width: 80%;
        flex-direction: column-reverse;
        align-items: center;
        border: 2px solid #2A4C21;
    }
    .content .image-container .Photo{
        width: 200px; /* Largeur de l'image */
        height: auto; /* Hauteur automatique pour conserver les proportions */
        margin: 20px 0 20px 0; 
        border-radius: 12px;
        border: 3px solid #2A4C21;
    }

    .text-container {
        margin-left: 20px;
        margin-right: 20px;
        text-align: justify;
    }

    h2{
        text-align: initial;
    }
    .footer{
        position: relative !important;
    }
}

