.commande-container {
    display: flex;
    width: 100%;
    height: 95%;
}


.produits-container {
    width: 75%;
    padding: 20px;
    overflow-y: auto;
}

.panier-container {
    width: 25%;
    padding: 20px;
    background-color: #83BC2A; /* La couleur de fond verte */
    border-left: 1px solid #ddd;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
}

.section {
    margin-bottom: 40px;
}

.subcategory {
    margin-bottom: 20px;
}

.items-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 34px;
}

.titre_section{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 15px;
}

.item {
    width: 240px;
    height: 440px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
}

.item-image {
    width: 175px;
    height: 175px;
    object-fit: cover;
    border-radius: 8px;
    margin-left: 32.5px;
    margin-top: 32.5px;
    border: 4px solid #2A4C21;
    display: block; /* Pour que l'image soit un bloc */
}

.panier-item {
    border-bottom: 1px solid #2A4C21;
    padding: 10px 0;
    background-color: white;
    border-radius: 8px; /* Ajuste la valeur pour le rayon des coins arrondis */
    margin-bottom: 10px; /* Ajoute un peu d'espace entre les éléments */
}

.panier-header {
    background-color: white; /* Fond blanc pour le cadre commun */
    border-radius: 8px; /* Coins arrondis pour le cadre commun */
    padding: 10px; /* Espacement à l'intérieur du cadre commun */
    margin-bottom: 10px; /* Espacement sous le cadre commun */
    border: 1px solid #ddd; /* Bordure légère autour du cadre commun */
}

.timer h3{
    text-align: initial;
    margin-top: -20px;
    
}
.tooltip-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #F07D0C; /* Couleur du cercle */
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: 8px;
    cursor: pointer;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Ombre pour effet de surélévation */
  }
  
  .tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Ajuste selon le positionnement désiré */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip-icon:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

.panier-header p {
    text-align: initial;
}

p{
    text-align: initial;
}

.cart-summary-title{
    text-align: initial;
}


.add-to-cart-btn {
    background-color: #83BC2A;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    width: 80%;
}

.add-to-cart-btn.added {
    background-color:#2A4C21; /* Vert plus foncé */
    color: white;
    position: relative;
    width: 80%;
    cursor: default ;
}

.checkmark {
    font-size: 16px;
    margin-left: 8px; /* Espace entre le texte et la coche */
}

.add-to-cart-btn:hover {
    background-color: #6EA521;
}
.add-to-cart-btn.added:hover {
    background-color: #2A4C21;
}

.product-upload {
    position: fixed; /* Fixe le formulaire par rapport à la fenêtre du navigateur */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Fond semi-transparent pour l'arrière-plan */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Assure que le formulaire est au-dessus du reste du contenu */
}

/* Style pour les champs du formulaire */
.product-upload form {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-upload input,
.product-upload select,
.product-upload button {
    margin-bottom: 10px; /* Espacement entre les champs */
    padding: 10px; /* Espacement intérieur */
    border: 1px solid #ddd; /* Bordure des champs */
    border-radius: 4px; /* Coins arrondis */
}

.product-upload button {
    background-color: #4CAF50; /* Couleur de fond du bouton */
    color: white;
    border: none;
    cursor: pointer;
}

.product-upload button:hover {
    background-color: #45a049; /* Couleur de survol du bouton */
}

.product-upload .error {
    color: red;
    font-size: 14px;
}

/* Assurer que la section des produits est visible correctement */
.produits-container {
    margin-top: 20px;
}


.increase-btn:disabled {
    background-color: #ccc; /* Couleur de fond pour les boutons désactivés */
    cursor: not-allowed;    /* Curseur pour indiquer que le bouton est désactivé */
}

.decrease-btn:disabled {
    background-color: #ccc; /* Couleur de fond pour les boutons désactivés */
    cursor: not-allowed;    /* Curseur pour indiquer que le bouton est désactivé */
}

.validate-cart-btn{
    padding: 10px 20px;
    background-color: #2A4C21;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 10px; /* Espace entre les boutons */
}
.reservation-form {
    position: fixed; /* Pour le rendre superposé au contenu */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* Un fond semi-transparent pour focaliser sur le formulaire */
    z-index: 9999; /* S'assurer que le formulaire est au-dessus de tout autre contenu */
    overflow-y: auto;
}

.form-container {
    background-color: #fff; /* Fond blanc pour le formulaire */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Légère ombre pour le relief */
    width: 50%; /* Largeur fixe pour le formulaire */
    text-align: center; /* Centrer le texte et les boutons */
}

.confirm-btn, .close-btn {
    width: 50%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin-left: 25%;
}

.confirm-btn {
    background-color: #28a745; /* Couleur de fond pour le bouton de confirmation */
    color: white;
}

.confirm-btn:hover {
    background-color: #218838; /* Changement de couleur au survol */
}

.close-btn {
    background-color: #dc3545; /* Couleur de fond pour le bouton d'annulation */
    color: white;
}

.close-btn:hover {
    background-color: #c82333; /* Changement de couleur au survol */
}

/* Styles pour le formulaire de réservation */
.new-form {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.form-container-2 {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
    z-index: 1000;
}

.new-form button {
    padding: 10px 20px;
    background-color: #2A4C21;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-right: 20px; /* Espace entre les boutons */
}

.warning-text {
    font-size: 12px;
    color: red;
    margin-top: 30px;
    margin-left: -50px;
}

.info-text {
    margin-bottom: 15px;
    color: #2A4C21;
    margin-left: -10px;
}

.info-text-2 {
    margin-bottom: 15px;
    color: #2A4C21;
    margin-left: -30px;
}

label {
    margin-bottom: 5px;
    color: #2A4C21;
}

form{
    text-align: initial;
    margin-left: 30px;
}

.btn-51{
    display: flex;
    justify-content: center;
}

.add-button, .command-button{
    padding: 10px 20px;
    background-color: #2A4C21;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-right: 20px; /* Espace entre les boutons */
}

.availability-form {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 90%;
    max-width: 600px;
    max-height: 80vh; /* Limitez la hauteur maximale pour permettre le défilement à l'intérieur */
    overflow-y: auto; /* Ajoutez un défilement à l'intérieur du formulaire */
    border-radius: 8px;
}

.btn-dispo{
    padding: 10px 20px;
    background-color: #4CAF50; /* Couleur de fond du bouton */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-right: 20px; /* Espace entre les boutons */
}

.added-time-slots {
    margin-top: 20px; /* Espacement au-dessus de la section des créneaux ajoutés */
}

.added-time-slots h5 {
    margin-bottom: 10px; /* Espacement sous le titre de la section */
}

.added-time-slots ul {
    list-style-type: none; /* Enlever les puces de la liste */
    padding: 0; /* Enlever le padding par défaut */
}

.added-time-slots li {
    margin-bottom: 5px; /* Espacement entre les éléments de la liste */
}

.availability-form label {
    display: block; /* Pour que chaque label soit sur une nouvelle ligne */
    margin: 10px 0 5px; /* Espacement autour des labels */
    font-weight: bold; /* Texte en gras pour les labels */
}

.availability-form input[type="date"],
.availability-form input[type="time"] {
    width: calc(100% - 20px); /* Ajuster la largeur pour inclure le padding */
    padding: 8px; /* Espacement intérieur */
    margin-bottom: 10px; /* Espacement sous chaque champ */
    border: 1px solid #ccc; /* Bordure légère */
    border-radius: 4px; /* Coins arrondis */
}

.titre-h3{
    color: #2A4C21;
    text-align: center;
    font-weight: bold;
}


.order-list {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    z-index: 1005;
    width: 80%;
    max-height: 80vh; /* Ajoutez cette ligne pour limiter la hauteur */
    overflow-y: auto; /* Permet le défilement vertical */
    overflow-x: auto; /* Désactiver le défilement horizontal */
}

.order-list table {
    width: 100%;
    border-collapse: collapse;
}

.order-list th, .order-list td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.order-list th {
    background-color: #2A4C21;
    color: white;
}

.order-list tbody tr:hover {
    background-color: #f1f1f1;
}

.order-list td button {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.order-list td button:hover {
    background-color: #45a049;
}


.order-list .img2 {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: none;
    margin-left: 5px;
}

.titre-list{
    text-align: center;
}

.fermer-button{
    padding: 10px 20px;
    background-color: #4CAF50; /* Couleur de fond du bouton */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 20px;

}

.slot-item {
    margin-bottom: 10px;
    margin-left: 25%;
}

.slot-content {
    display: flex;
    align-items: center;
}

.slot-content strong {
    margin-right: 10px; /* Espacement entre la date et les créneaux */
}

.slot-content span {
    display: flex;
    align-items: center;
    margin-right: 15px; /* Espacement entre chaque créneau horaire */
}

.delete-button {
    margin-left: 5px; /* Espacement entre l'heure et le bouton "Supprimer" */
    padding: 3px 8px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.delete-button:hover {
    background-color: #ff1a1a;
}

.increase-btn{
    padding: 5px 8px;
    background-color: #2A4C21;
    color: white;
    margin-right: 7px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
}

.decrease-btn{
    padding: 5px 8px;
    background-color: #2A4C21;
    color: white;
    margin-right: 7px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
}

.remove-btn{
    padding: 5px 8px;
    background-color: #2A4C21;
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 4px;
}

.button-group {
    display: flex;
    gap: 10px; /* Espacement entre les boutons */
    margin-top: 10px;
    margin-left: 45px; /* Vous pouvez ajuster ou supprimer cette marge si nécessaire */
}

.edit-product-btn,
.delete-product-btn {
    background-color: #F07D0C;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    padding: 5px 8px;
    display: inline-block; /* Assurez-vous que les boutons sont en ligne */
}

.delete-product-btn {
    background-color: red;
}

.portion-coefficient{
    margin-right: 5px;
}

.item-header {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    margin-top: -20px;  
      
}
.info{
    display: flex;
    flex-direction: column; /* Organise le titre et la quantité en colonne */
    margin-top: 20px;
}

.item-header .item-image{
    width: 50px; /* Ajustez cette taille selon vos besoins */
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
    border: 3px solid #2A4C21;
}

.item-title {
    font-size: 1.1em;
    font-weight: bold;
    padding-bottom: 3px;
    margin: 0; /* Enlève la marge par défaut pour une meilleure mise en page */
    color:#2A4C21;
}

.item-quantity {
    font-size: 0.9em;
    color:#2A4C21;
    margin: 0; /* Enlève la marge par défaut */
    text-align: initial;
}

.item-price {
    font-size: 18px;
    font-weight: bold;
    color:#2A4C21;
    padding-top: 5px;
    padding-bottom: 15px;
    padding-left: 70%;
}

.cart-icon {
    display: none;
}

.panier-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Pour s'assurer que la modal est au-dessus de tout */
}

.modal-content {
    background-color: #83BC2A;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    position: relative;
    max-height: 80%; /* Limite la hauteur pour permettre le défilement */
    overflow-y: auto; /* Active le défilement si le contenu dépasse la hauteur maximale */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
}

.close-button:hover {
    background-color: #d32f2f;
}

.panier-header-mobile{
    background-color: white;
    border-radius: 8px; /* Coins arrondis pour le cadre commun */
    padding: 10px; /* Espacement à l'intérieur du cadre commun */
    margin-bottom: 10px; /* Espacement sous le cadre commun */
    border: 1px solid #ddd; /* Bordure légère autour du cadre commun */
}

.timer-mobile h3{
    text-align: initial;
    font-size: 14px;
    margin-top: -10px;
}

.validate-cart-btn-mobile{
    padding: 10px 20px;
    background-color: #2A4C21;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 10px; /* Espace entre les boutons */
}

.panier-item-mobile {
    padding: 10px 0;
    background-color: white;
    border-radius: 8px;
    margin-bottom: 10px;
}

/* En-tête de l'élément du panier en version mobile */
.item-header-mobile {
    display: flex; /* Utiliser flexbox pour aligner l'image et les informations sur une seule ligne */
    align-items: center; /* Centre verticalement les éléments de la ligne */
}

/* Image de l'élément en version mobile */
.item-image-mobile {
    width: 60px;
    height: 60px;
    border: 2px solid #2A4C21;
    margin-right: 10px; /* Espace entre l'image et les informations */
    margin-left: 40px;
}

/* Informations de l'élément (titre et quantité) en version mobile */
.info-mobile {
    display: flex;
    flex-direction: column; /* Aligne le titre et la quantité en colonne */
    justify-content: center; /* Centre verticalement les informations */
}


.item-title-mobile {
    font-size: 0.9em;
    margin-bottom: 5px; /* Espacement entre le titre et la quantité */
    color: #2A4C21;
    font-weight: bold;
}

.item-quantity-mobile {
    font-size: 0.8em;
    text-align: initial;

}

/* Détails supplémentaires de l'élément (comme le prix) */
.item-details-mobile .item-price-mobile {
    font-size: 18px;
    padding-left: 75%;
}

.add-to-cart-btn.loading {
    background-color: #ccc; /* Couleur pendant le chargement */
    cursor: not-allowed;
}

.spinner {
    border: 2px solid #f3f3f3; /* Couleur de fond */
    border-top: 2px solid #2A4C21; /* Couleur du spinner */
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
}

.bouton-non-dispo {
    background-color: #727070;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1em;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    width: 80%;
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@media all and (max-width: 1225px) {
    .item {
        width: 235px;
        height: 435px;
    }

    .item-image {
        width: 165px;
        height: 165px;
        margin-left: 35x;
        margin-top: 35px;
    }

    .button-group {
        display: flex;
        gap: 10px; /* Espacement entre les boutons */
        margin-top: 10px;
        margin-left: 43px; /* Vous pouvez ajuster ou supprimer cette marge si nécessaire */
    }


}

@media all and (max-width: 1205px) {
    .item {
        width: 220px;
        height: 420px;
    }

    .item-image {
        width: 155px;
        height: 155px;
        margin-left: 32.5x;
        margin-top: 32.5px;
    }

    .item p{
        font-size: 15px;
    }

    .button-group {
        display: flex;
        gap: 10px; /* Espacement entre les boutons */
        margin-top: 10px;
        margin-left: 35px; /* Vous pouvez ajuster ou supprimer cette marge si nécessaire */
    }


}
@media all and (max-width: 1145px) {
    .item {
        width: 210px;
        height: 410px;
    }

    .item-image {
        width: 150px;
        height: 150px;
        margin-left: 30x;
        margin-top: 30px;
    }

    .item p{
        font-size: 14px;
    }

    .button-group {
        display: flex;
        gap: 10px; /* Espacement entre les boutons */
        margin-top: 10px;
        margin-left: 30px; /* Vous pouvez ajuster ou supprimer cette marge si nécessaire */
    }


}

@media all and (max-width: 1105px) {
    .items-container{
        align-items: center; /* Centre les éléments verticalement (si nécessaire) */
        margin-left: 20px;
    }
    .titre_section{
        margin-left: 8px;
    }

    .item {
        width: 200px;
        height: 400px;
        margin-left: 5px;
        margin-top: 5px;
    }

    .item-image {
        width: 145px;
        height: 145px;
        margin-left: 27.5px;
        margin-top: 27.5px;
    }

    .item p{
        font-size: 13px;
    }

    .button-group {
        display: flex;
        gap: 10px; /* Espacement entre les boutons */
        margin-top: 10px;
        margin-left: 25px; /* Vous pouvez ajuster ou supprimer cette marge si nécessaire */
    }


}

@media all and (max-width: 1066px) {
    .items-container{
        align-items: center; /* Centre les éléments verticalement (si nécessaire) */
        margin-left: 20px;
    }
    .titre_section{
        margin-left: 5px;
    }

    .item {
        width: 200px;
        height: 400px;
        margin-left: 4px;
        margin-top: 4px;
    }

    .item-image {
        width: 145px;
        height: 145px;
        margin-left: 27.5px;
        margin-top: 27.5px;
    }

    .item p{
        font-size: 13px;
    }

    .button-group {
        display: flex;
        gap: 10px; /* Espacement entre les boutons */
        margin-top: 10px;
        margin-left: 25px; /* Vous pouvez ajuster ou supprimer cette marge si nécessaire */
    }


}

@media all and (max-width: 1062px) {
    .item {
        margin-left: 3px;
        margin-top: 3px;
    }
}

@media all and (max-width: 1058px) {
    .item {
        margin-left: 2px;
        margin-top: 2px;
    }
}

@media all and (max-width: 1054px) {
    .item {
        margin-left: 1px;
        margin-top: 1px;
    }
}



@media all and (max-width: 1050px) {
    .item {
        width: 240px;
        height: 440px;
        margin-left: 30px;
        margin-top: 30px;
    }

    .items-container{
        align-items: center; /* Centre les éléments verticalement (si nécessaire) */
        margin-left: 45px;
    }

    .titre_section{
        margin-left: 60px;
    }

    .item-image {
        width: 175px;
        height: 175px;
        margin-left: 32.5px;
        margin-top: 32.5px;
    }
    .item p{
        font-size: 16px;
    }
    .item h3{
        font-size: 20px;
    }
    .button-group {
        display: flex;
        gap: 10px; /* Espacement entre les boutons */
        margin-top: 10px;
        margin-left: 45px; /* Vous pouvez ajuster ou supprimer cette marge si nécessaire */
    }
    .panier-header p{
        font-size: 13px;
    }
    .item-header .info .item-title{
        font-size: 1.0em;
        margin-top: 10px;
    }

    .item-header .info .item-quantity{
        font-size: 0.9em;
    }

    .item-header .item-image{
        width: 40px;
        height: 40px;
        border: 2px solid #040604;
    }

    .item-details .item-price{
        font-size: 18px;
    }

    .panier-container .validate-cart-btn{
        font-size: 16px;
    }


}

@media all and (max-width: 1030px) {

    .items-container{
        align-items: center; /* Centre les éléments verticalement (si nécessaire) */
        margin-left: 40px;
    }

    .titre_section{
        margin-left: 50px;
    }
    

    .item-header .info .item-title{
        font-size: 1.0em;
        margin-top: 10px;
    }

    .item-header .info .item-quantity{
        font-size: 0.9em;
    }

    .item-header .item-image{
        width: 40px;
        height: 40px;
        border: 2px solid #040604;
    }

    .item-details .item-price{
        font-size: 18px;
    }

    .panier-container .validate-cart-btn{
        font-size: 16px;
    }
}

@media all and (max-width: 1010px) {

    .items-container{
        align-items: center; /* Centre les éléments verticalement (si nécessaire) */
        margin-left: 30px;
    }
    .titre_section{
        margin-left: 45px;
    }

    .item-header .info .item-title{
        font-size: 1.0em;
        margin-top: 10px;
    }

    .item-header .info .item-quantity{
        font-size: 0.9em;
    }

    .item-header .item-image{
        width: 40px;
        height: 40px;
        border: 2px solid #040604;
    }

    .item-details .item-price{
        font-size: 18px;
    }

    .panier-container .validate-cart-btn{
        font-size: 16px;
    }
}

@media all and (max-width: 990px) {

    .items-container{
        align-items: center; /* Centre les éléments verticalement (si nécessaire) */
        margin-left: 20px;
    }
    .titre_section{
        margin-left: 35px;
    }

    .item-header .info .item-title{
        font-size: 1.0em;
        margin-top: 10px;
    }

    .item-header .info .item-quantity{
        font-size: 0.9em;
    }

    .item-header .item-image{
        width: 40px;
        height: 40px;
        border: 2px solid #040604;
    }

    .item-details .item-price{
        font-size: 18px;
    }

    .panier-container .validate-cart-btn{
        font-size: 16px;
    }
}

@media all and (max-width: 960px) {

    .items-container{
        align-items: center; /* Centre les éléments verticalement (si nécessaire) */
        margin-left: 10px;
    }
    .titre_section{
        margin-left: 25px;
    }

    .item-header .info .item-title{
        font-size: 0.9em;
        margin-top: 10px;
    }

    .item-header .info .item-quantity{
        font-size: 0.8em;
    }

    .item-header .item-image{
        width: 40px;
        height: 40px;
        border: 2px solid #040604;
    }

    .item-details .item-price{
        font-size: 17px;
    }

    .panier-container .validate-cart-btn{
        font-size: 15px;
    }
}

@media all and (max-width: 940px) {

    .items-container{
        align-items: center; /* Centre les éléments verticalement (si nécessaire) */
        margin-left: 5px;
    }
    .titre_section{
        margin-left: 20px;
    }

    .item-header .info .item-title{
        font-size: 0.8em;
        margin-top: 10px;
    }

    .item-header .info .item-quantity{
        font-size: 0.7em;
    }

    .item-header .item-image{
        width: 40px;
        height: 40px;
        border: 2px solid #040604;
    }

    .item-details .item-price{
        font-size: 17px;
    }

    .panier-container .validate-cart-btn{
        font-size: 15px;
    }
}

@media all and (max-width: 910px) {

    .items-container{
        align-items: center; /* Centre les éléments verticalement (si nécessaire) */
        margin-left: 0px;
    }
    .titre_section{
        margin-left: 10px;
    }

    .item {
        width: 240px;
        height: 440px;
        margin-left: 25px;
        margin-top: 25px;
    }

    .timer h3{
        font-size: 17px;
    }

    .item-header .info .item-title{
        font-size: 0.8em;
        margin-top: 10px;
    }

    .item-header .info .item-quantity{
        font-size: 0.7em;
    }

    .item-header .item-image{
        width: 40px;
        height: 40px;
        border: 2px solid #040604;
    }

    .item-details .item-price{
        font-size: 17px;
    }

    .panier-container .validate-cart-btn{
        font-size: 15px;
    }
}

@media all and (max-width: 890px) {

    .items-container{
        align-items: center; /* Centre les éléments verticalement (si nécessaire) */
        margin-left: 0px;

    }
    .titre_section{
        margin-left: 5px;
    }

    .item {
        width: 240px;
        height: 440px;
        margin-left: 20px;
        margin-top: 20px;
    }

    .timer h3{
        font-size: 17px;
    }

    .item-header .info .item-title{
        font-size: 0.8em;
        margin-top: 10px;
    }

    .item-header .info .item-quantity{
        font-size: 0.7em;
    }

    .item-header .item-image{
        width: 40px;
        height: 40px;
        border: 2px solid #040604;
    }

    .item-details .item-price{
        font-size: 17px;
    }

    .panier-container .validate-cart-btn{
        font-size: 15px;
    }
}

@media all and (max-width: 870px) {

    .items-container{
        align-items: center; /* Centre les éléments verticalement (si nécessaire) */
        margin-left: 0px;
    }

    .titre_section{
        margin-left: 0px;
    }

    .item {
        width: 240px;
        height: 440px;
        margin-left: 15px;
        margin-top: 15px;
    }

    .timer h3{
        font-size: 17px;
    }

    .item-header .info .item-title{
        font-size: 0.8em;
        margin-top: 10px;
    }

    .item-header .info .item-quantity{
        font-size: 0.7em;
    }

    .item-header .item-image{
        width: 40px;
        height: 40px;
        border: 2px solid #2A4C21;
    }

    .item-details .item-price{
        font-size: 16px;
    }

    .panier-container .validate-cart-btn{
        font-size: 15px;
    }
}

@media all and (max-width: 850px) {

    .items-container{
        align-items: center; /* Centre les éléments verticalement (si nécessaire) */
        margin-left: 0px;

    }

    .item {
        width: 240px;
        height: 440px;
        margin-left: 12px;
        margin-top: 12px;
    }

    .timer h3{
        font-size: 17px;
    }

    .item-header .info .item-title{
        font-size: 0.7em;
        margin-top: 10px;
    }

    .item-header .info .item-quantity{
        font-size: 0.6em;
    }

    .item-header .item-image{
        width: 40px;
        height: 40px;
        border: 2px solid #2A4C21;
    }

    .item-details .item-price{
        font-size: 15px;
    }

    .panier-container .validate-cart-btn{
        font-size: 15px;
    }
}

@media all and (max-width: 842px) {

    .items-container{
        align-items: center; /* Centre les éléments verticalement (si nécessaire) */
        margin-left: 0px;

    }

    .item {
        width: 240px;
        height: 440px;
        margin-left: 12px;
        margin-top: 12px;
    }

    .timer h3{
        font-size: 17px;
    }

    .item-header .info .item-title{
        font-size: 0.7em;
        margin-top: 10px;
    }

    .item-header .info .item-quantity{
        font-size: 0.6em;
    }

    .item-header .item-image{
        width: 40px;
        height: 40px;
        border: 2px solid #2A4C21;
    }

    .item-details .item-price{
        font-size: 15px;
    }

    .panier-container .validate-cart-btn{
        font-size: 14px;
    }
}

@media all and (max-width: 840px) {
    .panier-container {
        display: none;
    }

    .items-container {
        width: 100%; /* Prendre toute la largeur de l'écran */
        padding: 0 10px; /* Espacement intérieur */
    }

    .cart-icon {
        z-index: 999;
        position: fixed;
        bottom: 20px;
        right: 20px;
        background-color: #f0f0f0;
        border: 1px solid #ccc;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        width: 60px; /* Ajustez la largeur du cercle selon vos besoins */
        height: 60px; /* Ajustez la hauteur du cercle selon vos besoins */
        display: flex; /* Centre l'image à l'intérieur du cercle */
        align-items: center;
        justify-content: center;
    }
    
    .cart-icon img {
        max-width: 100%; /* Assure que l'image ne dépasse pas du cercle */
        max-height: 100%; /* Assure que l'image ne dépasse pas du cercle */
        border-radius: 50%; /* Pour que l'image soit également arrondie si besoin */
        border: none;
    }
    .produits-container {
        width: 100%;
    }

    
    .cart-icon img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 50%;
        z-index: 999;
    }

    .cart-count {
        position: absolute;
        top: 16.7px;
        background-color: #2A4C21;
        color: white; /* Couleur du texte */
        border-radius: 50%;
        padding: 2px 6px;
        font-size: 12px; /* Ajustez la taille du texte */
        font-weight: bold;
        text-align: center;
    }

    .checkmark {
        font-size: 13px;
        margin-left: 8px; /* Espace entre le texte et la coche */
    }

    .remove-btn{
        padding: 5px 10px;
    }
    .decrease-btn{
        padding: 5px 10px;
        margin-right: 15px;
    }
    .increase-btn{
        padding: 5px 10px;
        margin-right: 15px;
    }
}

@media all and (max-width: 755px) {

    .items-container{
        align-items: center; /* Centre les éléments verticalement (si nécessaire) */
        margin-left: 20px;
    }

    .titre_section{
        margin-left: 24px;
    }

    .item {
        width: 200px;
        height: 400px;
        margin-left: 12px;
        margin-top: 12px;
    }

    .item-image {
        width: 130px;
        height: 130px;
        margin-left: 34px;
        margin-top: 34px;
    }

    .item h3{
        font-size: 17px;
    }

    .item p{
        font-size: 12.5px;
    }

    .button-group {
        display: flex;
        gap: 10px; /* Espacement entre les boutons */
        margin-top: 10px;
        margin-left: 25px; /* Vous pouvez ajuster ou supprimer cette marge si nécessaire */
    }


}

@media all and (max-width: 675px) {

    .items-container{
        display: flex;
        flex-wrap: wrap;
        align-items: center; /* Centre les éléments verticalement (si nécessaire) */
        margin-left: 0px;
    }

    .titre_section{
        margin-left: -20px;
    }

    .item {
        width: 150px;
        height: 330px;
        margin-left: -10px;
        margin-top: 0px;
    }

    .item-image {
        width: 120px;
        height: 120px;
        margin-left: 13px;
        margin-top: 13px;
    }

    .item h3{
        font-size: 13px;
    }

    .item p{
        font-size: 8.8px;
    }

    .button-group {
        display: flex;
        gap: 10px; /* Espacement entre les boutons */
        margin-top: 10px;
        margin-left: 9px; /* Vous pouvez ajuster ou supprimer cette marge si nécessaire */
    }

    .add-to-cart-btn{
        font-size: 0.6em;
    }

    .edit-product-btn{
        font-size: 11px;
    }
    .delete-product-btn{
        font-size: 11px;
    }

    
    .item-header-mobile .item-image-mobile{
        border-radius: 8px;
    }

    .form-container {
        width: 80%; /* Largeur fixe pour le formulaire */
        
    }

    .add-to-cart-btn.added {
        background-color:#2A4C21; /* Vert plus foncé */
        color: white;
    }

    .bouton-non-dispo{
        font-size: 0.6em;
    }
}