h1{
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: justify;
  color: #2A4C21;
}
h3{
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: justify;
  color: #2A4C21;
}
p.standard{
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: justify;
}
p.liste{
  text-align: justify;
  max-width: 800px;
  margin: 0 auto;
  padding: 0px;
}

.link{
  text-decoration: none;
  color: black;
}

.link:hover {
  color: #F07D0C;
}

.contact-info {
  display: flex;
  align-items: center;
  margin-top: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.enveloppe-icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  border: none;
}

.email-link {
  color: black;
  text-decoration: none;
  font-size: 16px;
}

.email-link:hover {
  color: #F07D0C;
}
.map-link2{
  text-decoration: none;
  color: black;
}

.map-link2:hover {
  color: #F07D0C;
}

@media all and (max-width: 768px) {
  p.liste{
    margin-left: 20px;
    text-align: initial;
  }
  .enveloppe-icon{
    margin-left: 20px;
  }
  .link{
    color: #F07D0C;
  }
  .email-link{
    color: #F07D0C;
  }
  .map-link2{
    color: #F07D0C;
    text-decoration: none;
  }
}
