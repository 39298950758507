.footer {
  width: 100%;
  background: #eaeaea;
  text-align: center;
  padding: 10px 0;
  color: #2A4C21;
}

.footer.fixed {
  position: fixed;
  bottom: 0;
}

.footer.relative {
  position: relative;
}

.footer-link {
  cursor: pointer;
  color: #F07D0C;
}

.footer-link:hover {
  color: #2A4C21;
}


@media all and (max-width: 768px) {
  .footer {
      font-size: 14px;
  }
}