h1{
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: justify;
    color: #2A4C21;
}
.map-link{
    text-decoration: none;
    color: black;
}

.map-link:hover {
    color: #F07D0C;
}

p {
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
    text-align: justify;
}

/* Fond semi-transparent derrière le modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fond noir avec transparence */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Mettre le modal au-dessus de tout le reste */
}

/* Conteneur du modal */
.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%; /* Vous pouvez ajuster la largeur selon vos besoins */
    max-width: 500px; /* Limite maximale de la largeur */
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3); /* Ajoute une ombre pour un effet de profondeur */
    z-index: 1001; /* S'assure que le contenu du modal est au-dessus de l'overlay */
    position: relative;
}


/* Champs de formulaire à l'intérieur du modal */
.modal-content label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
}

.modal-content input {
    width: 90%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.modal-content textarea{
    width: 95%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.modal-content button[type="submit"] {
    background-color: #2A4C21;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-left: 25%;
}

.modal-content button[type="submit"]:hover {
    background-color: #1a3a16;
}

.modal-content button[type="button"] {
    background-color: #ccc;
    color: black;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.modal-content button[type="button"]:hover {
    background-color: #aaa;
}

.button-info{
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #2A4C21;
    color: white;
    cursor: pointer;
    margin: 10px 0;
    margin-left: 23px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.button-info:hover {
    background-color: #1a3a16;
}

@media all and (max-height: 722px) {
    .footer{
        position: relative !important;
    }
}

@media all and (max-width: 768px) {
    .map-link{
        color: #F07D0C;
    }
    p{
        text-align: initial;
    }
    .footer{
        position: relative !important;
    }
}