h1{
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: justify;
    color: #2A4C21;
}
.test{
    font-family: arial; 
    margin: 0;
}
.container2 {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: justify;
}


.actualite {
    display: flex;
    flex-direction: column; /* Change to column to stack elements vertically */
    align-items: center; /* Center elements horizontally */
    margin-bottom: 20px;
    border: 2px solid #2A4C21;
}

.actualite img {
    width: auto; /* Ajuste la largeur de l'image selon tes besoins */
    height: 200px;
    margin: 20px 0 10px 0;
    border: 4px solid #2A4C21;
    
}

.texte {
    text-align: center; /* Center text within the .texte container */
    width: 100%; /* Ensure text container takes full width */
}

.texte h2 {
    font-size: 24px;
    margin: 5px auto; /* Adjust margins as needed */
}

.texte .publication {
    font-size: 14px;
    font-style: italic;
    color: #888;
    margin-bottom: 10px; /* Space between date and text */
}

.texte p {
    font-size: 16px;
    line-height: 1.5;
    
}
.publication{
    font-style: italic;
    color: #888;
}

.add-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #2A4C21;
    color: white;
    cursor: pointer;
    margin: 10px 0;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.add-button:hover {
    background-color: #1a3a16;
}

/* Overlay pour le formulaire d'ajout d'actualité */
.actu-upload {
    position: fixed; /* Fixe le formulaire par rapport à la fenêtre du navigateur */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Fond semi-transparent pour l'arrière-plan */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Assure que le formulaire est au-dessus du reste du contenu */
}

/* Contenu du formulaire d'ajout d'actualité */
.actu-upload form {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.actu-upload h2 {
    margin-top: 0;
    color: #2A4C21;
}

.actu-upload input, .actu-upload textarea {
    width: 100%;
    margin-bottom: 10px;
    border: 2px solid #2A4C21;
    border-radius: 5px;
    padding: 10px;
}

.actu-upload textarea {
    resize: vertical;
    min-height: 100px;
    width: 105%;
}

.button-container {
    display: flex;
    gap: 10px;
}

.button-container button {
    padding: 10px 20px;
    background-color: #2A4C21;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.button-container button:hover {
    background-color: #1a3a16;
}

.error {
    color: red;
    margin-top: 10px;
    font-size: 14px;
}

.delete-button {
    margin-top: 10px;
    padding: 5px 10px;
    color: white;
    background-color: red;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.delete-button:hover {
    background-color: darkred;
}

.help-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.help-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 600px;
    width: 100%;
    text-align: center;
}

.help-content h2 {
    margin-top: 0;
}

.help-content ul {
    list-style-type: none;
    padding: 0;
}

.help-content ul li {
    margin-bottom: 10px;
}

.edit-button {
    margin: 10px;
    padding: 10px 15px;
    color: white;
    background-color: blue;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.edit-button:hover {
    background-color: darkblue;
}

.help{
    text-align: initial; 
}
.delete-button-actu{
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #ff4d4d;
    color: white;
    cursor: pointer;
    margin: 10px 0;
    margin-left: 23px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.delete-button-actu:hover {
    background-color: #ff1a1a;
}



@media all and (max-width: 768px) {
    .actualite {
        flex-direction: column;
        align-items: center;
        border: 2px solid #2A4C21;
    }

    .actualite img {
        width: auto;
        height: 150px;
        margin: 20px 0 10px 0; 
    }

    .texte {
        width: 100%;
        text-align: center;
    }

    .texte, .publication {
        margin: 0;
    }


    .texte h2 {
        text-align: initial;
        margin-top: 20px;
    }
}