.container{
    position: relative;
    margin:auto;
    width: 100%; height: 250px;
    background-image: url(Image/header-photo.jpg); /* pour le fond c'est ça qui coince*/
    background-size: cover; /* Ajuste la taille de l'image pour couvrir le conteneur */
    background-repeat: no-repeat; /* Empêche la répétition de l'image */
    background-position: right center; /* Positionne l'image à droite, centré verticalement */
    overflow-x: hidden;
}

.information{
    position: absolute;
    bottom: 0;
    width: 94%;
    height: 65px;
    background-color: #83BC2A;
}

.profil{
    position: absolute;
    display: flex; 
    align-items: center;
    bottom: 3px; left: 8%;
}
.name{
    position: absolute;
    top: 35%;
    left: calc(160px + 10%); /* Ajuste la position horizontale du texte par rapport au logo  car le width du logo = 160px*/
    font-family: Pacifico-Regular;
    color:white;
    margin-left: 1.5em;
    font-size: 1.5em;
}

.profil img{
    width: 200px; height: 200px;
    border-radius: 50%;
    border: 3px solid #83BC2A;
}

.u1{
    position: relative;
    width: 100%;
    display: flex;
    padding-left: 25%;
    height: 100%;
}

.titre_header{
    margin-left: 10px;
    list-style: none;
    cursor:pointer; 
    color: #2A4C21;
    font-size: 1.1em;
    padding: 1.5em 1em;
    margin-right: 2%;
    transition: border-bottom 0.3s;
    font-weight: bold;
}
.titre_header2{
    /*margin-left: 10px;*/
    list-style: none;
    cursor:pointer; 
    /*color: #F07D0C;*/
    /*font-size: 1.1em;*/
    /*padding: 1.5em 1em;*/
    /*margin-right: 2%;*/
    /*transition: border-bottom 0.3s;*/
    /*font-weight: bold;*/
}

.titre_header:hover, .active{
    color: #F07D0C;
    border-bottom: 4px solid #F07D0C;
    /*background-color: yellowgreen;*/
}

.information2 {
    position: absolute;
    bottom: 0;
    left: 94%;
    width: 6%; 
    height: 65px; 
    background-color: #2A4C21;
}

.personne {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #83BC2A; 
    position: absolute;
    top: 40%; 
    left: 50%; 
    transform: translate(-50%, -50%); /* Centrage de `#personne` */
    cursor: pointer;
}

.personne::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 20px;
    background-color: #83BC2A;
    top: 20px;
    left: -10px;
    border-radius: 50% 50% 0 0;
}

.personne2 {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #F07D0C; 
    position: absolute;
    top: 40%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.personne2::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 20px;
    background-color: #F07D0C;
    top: 20px;
    left: -10px;
    border-radius: 50% 50% 0 0;
}

/* Carré vert avec une coche */
.personne2::before {
    content: "✔"; /* Symbole de coche */
    position: absolute;
    width: 12px;
    height: 12px;
    /*border: 2px solid #83BC2A; /* Bordure verte */
    color: #83BC2A; /* Couleur de la coche */
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 24px; /* Ajusté pour que le carré soit juste au-dessus du torse */
    left: 2px;
    background-color: #F07D0C; /* Fond blanc à l'intérieur du carré */
    /*border-radius: 3px; /* Angle légèrement arrondi */
    z-index: 1;
}

.menu-icon {
    display: none; /* Cacher l'icône de menu sur les grands écrans */
  }

.titre_header .mobile-only{
    display: none;
}*

.close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
    color: #2a4c21;
  }

.clone-icon{
    display: none;
}

@media all and (max-width: 1200px) {
    .titre_header {
        padding: 1.5em 0.4em;
      }
}

@media all and (max-width: 1030px) {
    .titre_header {
        padding: 1.5em 0em;
      }
    
    .profil img{
        width: 150px;
        height: 150px;
    }
}

@media all and (max-width: 930px) {
    .titre_header {
        padding: 1.5em 0em;
        font-size: 1em;
      }
}

@media all and (max-width: 840px) {
    .profil img {
      width: 150px;
      height: 150px;
    }
    .menu-icon {
        display: block;
        position: absolute;
        right: 20px;
        top: 13px;
        font-size: 30px;
        cursor: pointer;
        color: #2A4C21;
    }
    
    .u1 {
        display: none;
    }

    .dropdown-menu {
        display: flex;
        flex-direction: column;
        background-color: #83bc2a;
        position: fixed;
        top: 0;
        width: 100%;
        height: 100vh; /* Hauteur de 100% de la vue (viewport height) */
        left: 0;
        z-index: 1005; /* Assure que le menu déroulant soit au-dessus du reste du contenu */
      }
    
      .dropdown-menu .titre_header {
        margin: 10px 0;
        padding: 10px;
        text-align: center;
        color: #2a4c21;
        font-weight: bold;
      }
    
    .personne{
        display: none;
    }
    .personne2{
        display: none;
    }

    .information{
        width: 100%;
    }

    .information2{
        width: 0;
    }

    .titre_header .mobile-only{
        margin-left: 10px;
        list-style: none;
        cursor:pointer; 
        color: #2A4C21;
        font-size: 1.1em;
        padding: 1.5em 1em;
        margin-right: 2%;
        transition: border-bottom 0.3s;
        font-weight: bold;
    }

    .dropdown-menu.opened .close-icon {
        display: block; /* Affiche l'icône de fermeture lorsque le menu est ouvert */
      }
    
      .container {
        background-position: center center; /* Ajuste la position pour les écrans plus petits */
    }
  }


