/* Conteneur principal pour les sections */
.TOTO {
    max-width: 850px;
    margin: 0 auto;
    padding: 20px;
    text-align: justify;
}

/* Style pour les images */
.img1{
    width: 170px;
    height: 170px;
    border: 4px solid #2A4C21;
    margin-right: 17px;
    margin-bottom: 22px;
    margin-left: 5px;
    display: block; /* Pour que l'image soit un bloc */
}

/* Style pour les titres */
h2 {
    color: #2A4C21;
}

/* Texte préformaté pour les sections HTML */
.preformatted-text {
    white-space: pre-wrap;
}

/* Style pour le formulaire de texte */
.test-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* Style de base pour le textarea */
textarea {
    width: 100%;
    min-height: 100px;
    resize: vertical;
    box-sizing: border-box;
    padding: 10px;
    border: 2px solid #2A4C21;
    border-radius: 5px;
    font-size: 16px;
    font-family: Arial, sans-serif;
}

/* Style pour le textarea lorsqu'il est agrandi */
.expanded-textarea {
    width: 80%;
    min-height: 300px;
}

/* Conteneur pour le texte et les boutons */
.text-button-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Style pour les boutons d'édition */
.edit-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #2A4C21;
    color: white;
    cursor: pointer;
    margin: 10px 0;
    margin-left: 23px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.edit-button:hover {
    background-color: #1a3a16;
}

/* Style pour le conteneur d'images */
.images-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    margin-left: 20px;
}

/* Style pour les wrappers d'images */
.image-wrapper {
    position: relative;
    display: inline-block;
    width: 170px; /* Largeur du wrapper doit correspondre à la largeur de l'image */
    margin-right: 30px;
    margin-bottom: 30px;
}

/* Style pour les images dans les wrappers */
.image-wrapper img {
    border: 4px solid #2A4C21;
}

/* Style pour le bouton de suppression d'image */
.image-wrapper .delete-button {
    position: absolute;
    bottom: 15%; /* Positionne le bouton légèrement en dehors du bas de l'image */
    right: -40px; /* Positionne le bouton légèrement à droite du bord de l'image */
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #ff4d4d;
    color: white;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    z-index: 10; /* Assure que le bouton est au-dessus des autres éléments */
}

.image-wrapper .delete-button:hover {
    background-color: #cc0000;
}

/* Style pour le titre sous l'image */
.image-title {
    display: block;
    width: 177px; /* Largeur du titre doit correspondre à celle de l'image */
    text-align: center;
    font-size: 14px;
    color: #2A4C21;
    background-color: #83BC2A; /* Légère teinte verte pour le fond du titre */
    padding: 5px;
    border-radius: 5px;
    box-sizing: border-box;
    margin-left: 5px;
    font-weight: bold;
}

/* Style pour le formulaire d'ajout d'image */
.image-upload {
    position: fixed; /* Fixe le formulaire par rapport à la fenêtre du navigateur */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Fond semi-transparent pour l'arrière-plan */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Assure que le formulaire est au-dessus du reste du contenu */
    padding: 20px;
    box-sizing: border-box;
}

/* Style du formulaire d'ajout d'image */
.image-upload form {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Style du titre dans le formulaire d'ajout d'image */
.image-upload h2 {
    margin-top: 0;
    margin-bottom: 20px; /* Espace entre le titre et les autres éléments du formulaire */
    color: #2A4C21;
}

/* Style pour le champ de fichier */
.image-upload input[type="file"] {
    display: block;
    margin-bottom: 10px;
    border: 2px solid #2A4C21;
    border-radius: 5px;
    padding: 5px;
}

/* Style pour les boutons dans le formulaire d'ajout d'image */
.image-upload button {
    padding: 10px 20px;
    background-color: #2A4C21;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 10px; /* Espace entre les boutons */
}

/* Style au survol des boutons dans le formulaire d'ajout d'image */
.image-upload button:hover {
    background-color: #1a3a16;
}

/* Style pour les messages d'erreur dans le formulaire */
.image-upload .error {
    color: red;
    margin-top: 10px;
    font-size: 14px;
}

/* Style pour l'overlay de l'aide */
.help-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow-y: auto;
}

/* Style pour le contenu de l'aide */
.help-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 80%;
    max-height: 80vh; /* Limite la hauteur à 80% de la hauteur de la vue */
    overflow-y: auto; /* Active le défilement vertical si le contenu dépasse */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.help-content h2 {
    margin-top: 0;
    color: #2A4C21;
}

/* Style pour le conteneur des boutons */
.button-container {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;
}

.page-container {
    display: flex;
    justify-content: center; /* Centre horizontalement */
    align-items: center; /* Centre verticalement */
    padding: 20px; /* Optionnel : ajoute de l'espace autour du conteneur */
}

.newsletter-signup {
    width: 760px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optionnel : ajoute une ombre légère pour un meilleur effet visuel */
}

.newsletter-signup h2 {
    margin-bottom: 15px;
}

.newsletter-signup form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.newsletter-signup input[type="email"] {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 80%;
    max-width: 300px;
}

.newsletter-signup button {
    padding: 10px 20px;
    background-color: #83BC2A;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

.newsletter-signup button.edit-button-3 {
    margin-top: 10px;
    background-color: #2A4C21;
    margin-left: 20px;
}

.newsletter-signup .error {
    color: red;
    margin-top: 10px;
}

.subscribers-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.subscribers-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto; /* Permet le défilement vertical */
}

.subscribers-content h2 {
    margin-bottom: 15px;
}

.subscribers-content ul {
    list-style: none;
    padding: 0;
}

.subscribers-content li {
    padding: 5px 0;
}

.subscribers-content .button-container {
    margin-top: 20px;
}

.button-fermer{
    padding: 10px 20px;
    background-color: #83BC2A;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

.success {
    color: green;
    font-size: 1.2em;
    margin-top: 10px;
}

.button-sup {
    padding: 7px 14px;
    background-color: #ff4d4d; /* Couleur de fond (orange) */
    color: white; /* Couleur du texte */
    border: none; /* Pas de bordure */
    border-radius: 5px; /* Coins arrondis */
    cursor: pointer; /* Curseur en forme de main */
    font-size: 14px; /* Taille du texte */
    transition: background-color 0.3s ease; /* Transition douce pour le changement de couleur */
    margin-left: 20px;
}

.button-sup:hover {
    background-color: #cc0000; /* Changement de couleur au survol */
}

@media all and (max-height: 722px) {
    .footer{
        position: relative !important;
    }
}

@media all and (max-width: 768px) {
    .img1 {
        width: 125px;
        height: 125px;
        border: 3px solid #2A4C21;
        margin-right: 0px;
    }
    .images-container {
        display: flex;
        justify-content: center; /* Centre les éléments horizontalement */
        align-items: center; /* Centre les éléments verticalement */
        flex-wrap: wrap; /* Permet aux images de se retourner à la ligne si nécessaire */
        padding: 20px;
        gap: 0px;
        margin-top: 20px;
        margin-left: 0px;
    }
    h2{
        text-align: initial;
    }
    .image-wrapper {
        margin-right: -15px;
        margin-bottom: 30px;
    }
    .image-title {
        width: 130px; /* Largeur du titre doit correspondre à celle de l'image */
    }

    .image-wrapper .delete-button {
        bottom: 20%; /* Positionne le bouton légèrement en dehors du bas de l'image */
        right: 32px; /* Positionne le bouton légèrement à droite du bord de l'image */
        padding: 10px;
    }
    .footer{
        position: relative !important;
    }
}