.footer2 {
    position: relative;
    bottom: 0;
    width: 100%;
    background: #eaeaea;
    text-align: center;
    padding: 10px 0;
    color: #2A4C21;
  }
  
  .footer2-link {
    cursor: pointer;
    color: #F07D0C;
  }
  
  .footer2-link:hover {
    color: #2A4C21;
  }

  @media all and (max-width: 768px) {
    .footer2 {
      font-size: 14px;
    }
  }